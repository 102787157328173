:root{
  --background: rgba(224,235,227,0.8);
  --background-top:#B3A394;
  --ingredient:#3c8e4c;
  --ingredient-count: rgba(21,49,34, 0.5);
  --button:#859EAD;
  --text:#e3e8e1;
  --infobox:#959AB1;
  user-select: none;
  color:var(--text);
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box; 
 }

.App {
  text-align: center;
  background-color: var(--background)
}

element {
  background-color: var(--background);
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}



.App-header {
  /*background-color: #282c34;*/
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#ingredients {
  display: flex;

}

#filters {

  display: flex;

  margin-left: 2vw;
  margin-right: 2vw;
  margin-top: 1px;
}

#filters * {
  /*border-style:groove;
  border-width: 1px 0px 0px 0px;
  border-color: rgba(248, 59, 34, 0.2);*/
}

#filters span {
  border-width: 0px;
}

.filterDiv {
  color: black;
  width: 50%;
}

.filterFlex{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.cocktailList {
  display: flex;
  flex-wrap: wrap;
  max-height: 30vh;
  overflow-y:scroll;
  overflow-x: hidden;
  align-items: center;
  justify-items: center;
  justify-content: center;
  flex-direction: row;

}

#ingHead {
  max-width: 100vw;

  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 25px;
  flex-wrap: wrap;


}

#resultHead {
  max-width: 100vw;
  max-height: 10vh;
  display: flex;
  justify-content: center;
  align-items: baseline;
  column-gap: 25px;
  flex-wrap: wrap;

}


#textInputDiv {
  /*border-width: 1px 0px px 0px;
  border-style: groove;
  border-color: black;*/
  max-width: 210px;
}

.filterDiv h3 {
  border-style: groove;
  border-width: 0px 0px 2px 0px;
  border-color: black;
}

#textInput {
  max-height: 40px;
  width: 150px;
  max-width: 200px;
  font-size: max(0.4vw, 20px);
  margin-top: 5px;
}

#textInputSpacer {

}


#textInput+h3{

}

h1 {


}



h3 {
  font-size: max(2vw, 25px);
  font-family: Verdana, Geneva, Tahoma, sans-serif;

}

.active-header{
  color:black;
}

.inactive-header {
  color:#959AB1;
}

body {
  background-color: var(--background);
  min-height: 1000px;
  

}


.displayIng {
  margin-left: 0px;
  margin-right: 5px;
  max-width: 150px;
  min-width: 150px;
  background-color:  var(--ingredient);
  background-repeat: no-repeat;
  background-size: 110% 100%;
  min-height: 30px;
  margin-top: 5px;
  /*font-size: min(4vw,15px);*/
  display:flex;
  cursor: pointer;
  color: var(--text);
  font-family: Arial, Helvetica, sans-serif;
  border-radius: 3px;
  }

.ingList {
  display: flex;
  flex-wrap: wrap;
  max-height: 30vh;
  overflow-y:scroll;
  overflow-x: hidden;
  align-items: center;
  
  justify-content: space-between;
  margin-left: 2vw;
  margin-right: 2vw;
  border-color: rgb(115, 185, 190);
  border-style: solid;
  border-width: 2px 0px 2px 0px;
  margin-top: 2px;
}

.fill-div {
  display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
}

.ingredient {
  margin-left: 0px;
  width:80%;
  vertical-align: middle;
  align-items:center;
  display:flex;
  justify-content: center;
  text-transform: capitalize;
}

.countIng{
  display:flex;
  float:right;
  min-height: 40px;
  min-width: 20%;
  color: var(--text);
  background-color: var(--ingredient-count);
  vertical-align: middle;
  align-items:center;
  justify-content: center;
  border-radius: 0px 3px 3px 0px;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media only screen and (max-width:325px) {
  .displayIng{
    font-size: min(4vw,15px);
    margin-right: 2px;
    width: auto;
    min-width: 90px;
  }
  h3 {
    font-size: medium;

  }

}

@media only screen and (max-width:215px) {
  h3 {
    font-size: small;
  }

}